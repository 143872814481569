@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  display: flex;
  justify-content: center;
}

body {
  color: white;
  margin: 0px;
  max-width: 1150px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #081427;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
}

body::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
::selection {
  background: rgba(74, 222, 222, 0.5 );
}

/* !!!NAVBAR CSS STYLING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

#navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4adedd;
  margin-top: 15px;
  padding-right: 25px;
  padding-left: 25px;
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#navbar-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links:hover, .nav-links:focus{
  filter: brightness(0) invert(1);
}

#linkedin-github-navbar {
  display: flex;
  margin-left: 15px;
}

.nav-links {
  font-size: 26px;
  margin-left: 15px;
  font-weight: 600;
}

.personal-links{
  font-size: 18px;
  margin-left: 15px;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.personal-links:hover, .personal-links:focus{
  filter: brightness(0) invert(1);
}

/* !!!HOME CSS STYLING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

#home{
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 110px 0px 60px 0px;
}

.home-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkedin-pic {
  width: 280px;
  border: 5px solid white;
  border-radius: 140px;
}

.brodie-kime{
  margin-top: 12px;
}

.resume-download {
  background-color: #4adedd;
  color: #081427;
  border: 2px solid #081427;
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
}
  
.resume-download:hover {
  background-color: #081427;
  color: #4adedd;
  border: 2px solid #4adedd;
}

/* !!!ABOUT ME CSS STYLING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  
.boxes{
  background-color: rgba(255, 255, 255, 0.55);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
}
  
.boxes-about{
  background-color: rgba(255, 255, 255, 0.6);
  padding: 5px;
  border-radius: 15px;
}
  
h1 {
  font-size: 48px;
  margin: 0;
  padding: 0px;
  text-align: center;
}
  
.code-deployed-website-hyperlinks {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
  
#about-me-title{
  margin-bottom: 30px;
  font-weight: 800;
}
    
.about-container {
  max-width: 1150px;
  margin: 50px auto 80px auto;
  padding: 35px 50px 35px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.about-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}
   
.about-content p {
  font-size: 18px;
  line-height: 1.5;
  margin-left: 75px;
}
  
.about-box {
  border: 2px solid #ccc;
  padding: 10px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  font-size: 18px;
}
  
#about-me-text p{
  margin: 12px 10px;
}
  
  /* !!!PROJECTS CSS STYLING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  
.projects{
  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 1150px;
}

.samplewav-logo {
  width: 100%;
  margin-bottom: 30px;
}
  
.best-fest-logo {
  width: 120px;
  margin-bottom: 15px;
}
  
.save-the-rave-logo {
  width: 250px;
  margin-bottom: 15px;
}
  
.projects-side-by-side {
  display: flex; 
  justify-content: center;
}
  
.project {
  width: 10vw;
}
    
.project-pics{
  width: 100%;
  border-radius: 10px;
}
 
.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  margin: 5px 0px;
}
  
.skill {
  border: 2px solid white;  
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  margin: 6px 0px;
}
  
.skills-about {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
  
.skill-about {
  border: 2px solid white;  
    padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0px;
}
  
.experience-soft-skills-titles{
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin-bottom: px;
}
  
#experience-soft-skills-flex{
  display: flex;
  margin-bottom: 15px;
  width: 100%; 
}
  
.code-deployed-website-links{
  color: #4adedd;
  display: flex;
  gap: 10px; 
}

.samplewav-deployed-website-links{
  color: #4adedd;
  display: flex;
  align-items: center;
  gap: 10px; 
  margin-left: 30px;
}
  
em {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}
  
#full-stack {
  font-size: 22px;
  margin-top: 10px;
  margin-left: 3px;
}
  
.project-description {
  margin-bottom: 20px;
}

#projects{
  display: flex;
  justify-content: center;
}

.github-website-font {
  font-size: 14px;
}

#samplewav {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  border: 2px solid #ccc;
  padding: 20px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  font-size: 16px;
  width: 31%;
}

#best-fest {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  border: 2px solid #ccc;
  padding: 20px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  font-size: 16px;
  width: 31%;
}

#save-the-rave{
  display: flex !important;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  border: 2px solid #ccc;
  padding: 20px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  font-size: 16px;
  width: 31%;
}

/* !!!CONTACT CSS STYLING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

#contact{
  width: 800px;
  margin: 70px auto 70px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 5px 0px 30px 0px;
   border-radius: 15px;     
}

.contact {
  font-size: 30px;
  text-align: center;
  margin: 10px;     
}

.contact-button{
  background-color: #4adedd;
  color: #081427;
  border: 2px solid #081427;
  border-radius: 50px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}
    
.contact-button:hover, .contact-button:focus {
  background-color: #081427;
  color: #4adedd;
  border: 2px solid #4adedd;
}


/* !!!FOOTER CSS STYLING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

#footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

/* !!!HAMBURGER/COLUMN CSS STYLING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

.column-layout #hamburger-menu {
  display: flex;
}

.column-layout #navbar-right {
  flex-direction: column;
  display: none;
}

.column-layout .nav-links,
.column-layout .personal-links {
  margin-left: 0;
  margin-bottom: 15px;
}

.column-layout #linkedin-github-navbar,
.column-layout #main-links{
  display: flex;
  flex-direction: column;
  margin-left: 0;
}

.column-layout #linkedin-github-navbar .personal-links {
  margin-top: 15px;
}

.column-layout .nav-links,
.column-layout .personal-links {
  text-align: right;
  align-self: flex-end;
}

.column-layout .logo{
  display: flex;
  align-items: flex-start;
}

#hamburger-menu {
  position: fixed;
  right: 20px;
}

#hamburger-menu:hover {
  color: white;
}

.hamburger {
  font-size: 36px;
  background-color: transparent;
  color:#4adedd;
  border: none;
  cursor: pointer;
  outline: none;
  display: none;
}

.hidden {
  display: none;
}

#navbar-right.open {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 60px;
  right: 20px;
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(8, 20, 39);
  border: 3px solid white;
}

/* !!!MEDIA QUERIES CSS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

@media (max-width: 1200px) {
  .about-container {
    max-width: 1000px;
    padding: 35px 50px 35px 50px;
  }

  #contact{
    width: 600px;
  }
  
  .contact {
    font-size: 24px;    
  }

  .projects {
    max-width: 1000px;
  }

  .samplewav-logo {
    width: 100%;
    margin-bottom: 15px;
  }
    
  .best-fest-logo {
    width: 70px;
    margin-bottom: 15px;
  }
    
  .save-the-rave-logo {
    width: 160px;
    margin-bottom: 15px;
  }

  .samplewav-deployed-website-links{
    color: #4adedd;
    display: flex;
    align-items: center;
    gap: 10px; 
    margin-left: 0px;
  }
}

@media (max-width: 1100px) {
  .about-container {
    max-width: 880px;
    padding: 35px 50px 35px 50px;
    
  }

  .projects {
    max-width: 880px;
  }
}

@media (max-width: 1000px) {
  .about-container {
    max-width: 815px;
    padding: 20px 35px;
  }

  #experience-soft-skills-flex{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%; 
  }
  
  .projects-side-by-side {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-box{
    margin-top: -2px;
  }

  #samplewav-code-links{
    display: flex;
  }

  #samplewav {
    font-size: 16px;
    width: 815px;
  }


  #best-fest {
    font-size: 16px;
    width: 815px;
  }

  #save-the-rave {
    font-size: 16px;
    width: 815px;
  }

  .samplewav-logo {
    width: 400px;
    margin-bottom: 15px;
  }
    
  .best-fest-logo {
    width: 100px;
    margin-bottom: 15px;
  }
    
  .save-the-rave-logo {
    width: 190px;
    margin-bottom: 15px;
  }
}

@media (max-width: 925px) {

  .about-container {
    max-width: 700px;
    padding: 20px 35px;
  }

  #contact{
    width: 510px;
  }

  .projects{
    max-width: 510px;
  }

  #samplewav{
    width: 700px;
  }

  #best-fest{
    width: 700px;

  }

  #save-the-rave{
    width: 700px;
  }

  .contact-button{

    border-radius: 50px;
    padding: 10px 20px;

  }
}

@media (max-width: 820px) {

  .about-container {
    max-width: 650px;
    padding: 20px 35px;
  }

  #contact{
    width: 460px;
  }

  .projects{
    max-width: 460px;
  }

  #samplewav{
    width: 650px;
  }

  #best-fest{
    width: 650px;

  }

  #save-the-rave{
    width: 650px;
  }
}

@media (max-width: 767px) {

  /* FONT SIZE CHANGE HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  
  body {
    font-size: 14px;
    font-weight: 400;
  }

  h1{
    font-size: 40px;
  }
  
  #linkedin-github-navbar {
    display: flex;
    margin-left: 15px;
  }
  
  #about-me-title{
    font-size: 34px;
    margin: 0px;
    margin-bottom: 5px;
  }

  #about-me-text {
    font-size: 14px
  }


  .nav-links {
    font-size: 18px;
    margin-left: 15px;
    font-weight: 600;
  }
  
  .personal-links{
    font-size: 12px;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  
  .icons {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  .about-container {
    max-width: 530px;
    padding: 20px 35px;
  }

  #contact{
    width: 400px;
  }

  .projects{
    max-width: 530px;
  }

  #samplewav{
    width: 530px;
  }

  #best-fest{
    width: 530px;

  }

  #save-the-rave{
    width: 530px;
  }

  .linkedin-pic {
    width: 200px;
    border: 5px solid white;
    border-radius: 140px;
  }

em {
font-size: 16px;
}

  .skill {
    font-size: 12px;
 
  }
  
  .contact {
    font-size: 18px;    
  }

  .contact-button{
    font-size: 16px;
    border-radius: 50px;
    padding: 10px 20px;
  }

}

@media (max-width: 635px) {

  .about-container {
    max-width: 460px;
    padding: 10px 15px;
  }

  #contact{
    width: 340px;
  }

  .projects{
    max-width: 460px;
  }

  #samplewav{
    width: 460px;
  }

  #best-fest{
    width: 460px;

  }

  #save-the-rave{
    width: 460px;
  }
  
}

@media (max-width: 572px) {

  #about-me-text {
    font-size: 12px
  }

  h1{
    font-size: 30px;
  }

  #navbar-right{
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  #linkedin-github-navbar{
    margin-top: 6px;
  }

  .about-container {
    max-width: 365px;
    padding: 10px 15px;
  }

  #contact{
    width: 340px;
  }

  .projects{
    max-width: 365px;
  }

  #samplewav{
    width: 365px;
  }

  #best-fest{
    width: 365px;

  }

  #save-the-rave{
    width: 365px;
  }

  body {
    font-size: 12px;
    font-weight: 300;
  }
  
}

@media (max-width: 470px) {

 .skill-about {
  font-size: 12px;
 }

  .samplewav-deployed-website-links{
    display: flex;
    flex-direction: column;
  }

  .about-container {
    max-width: 300px;
    padding: 10px 15px;
  }

  #contact{
    width: 300px;
  }

  .projects{
    max-width: 300px;
  }

  #samplewav{
    width: 300px;
  }

  #best-fest{
    width: 300px;

  }

  #save-the-rave{
    width: 300px;
  }

  body {
    font-size: 12px;
    font-weight: 300;
  }
  
  .brodie-kime{
    font-size: 35px
  }

  #full-stack{
    font-size: 18px;
    word-wrap: normal;
  }

}

@media (max-width: 410px) {


  .about-container {
    max-width: 220px;
    padding: 10px 15px;
  }

  #contact{
    width: 225px;
  }

  .projects{
    max-width: 225px;
  }

  #samplewav{
    width: 225px;
  }

  #best-fest{
    width: 225px;

  }

  #save-the-rave{
    width: 225px;
  }

  body {
    font-size: 12px;
    font-weight: 300;
  }

 
  
  .brodie-kime{
    font-size: 35px
  }

  #full-stack{
    font-size: 18px;
    word-wrap: normal;
  }

  .contact-button{
    font-size: 14px;
  }
}